<template>
  <section class="site-section" id="next-section">
    <div class="container">
      <div class="row mb-3">
        <div class="col-12 text-center" data-aos="fade">
          <h2 class="section-title mb-3">Vyhlásenie o ochrane osobných údajov</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
            <p>Andrej Dula, ako slobodne podnikajúca osoba, uvedomujúc si svoju povinnosť chrániť súkromie a osobné údaje vyhlasuje, že vždy a včas vynaloží potrebné úsilie na dosiahnutie potrebnej ochrany súkromia a osobných údajov všetkých zamestnancov, zákazníkov a obchodných partnerov.</p>
            <p>Andrej Dula je samostatne zárobkovo činná osoba, podnikajúca na základe živnostenského oprávnenia, so sídlom Rakytovská cesta 6/C, Banská Bystrica, IČO: 45 389 934 je prevádzkovateľom, ktorý spracúva osobné údaje v súlade s Nariadením Európskeho parlamentu a rady (EÚ) 2016/679 o ochrane fyzických osôb pri spracúvaní osobných údajov a o voľnom pohybe takýchto údajov a v súlade s ustanoveniami zákona č. 18/2018 Z. z. o ochrane osobných údajov. Ako prevádzkovateľ prijal rad opatrení, ktorými bude predchádzať narušeniu práv osôb, o ktorých osobné údaje spracúva. Tieto opatrenia nie je možné zverejniť, aby nebolo zmarené úsilie prevádzkovateľa o ochranu práv osôb. Prevádzkovateľ má však záujem o komunikáciu a to nie len s každou osobou o ktorej spracúva osobné údaje, ale s každým, kto sa čo i len domnieva, že by prevádzkovateľ mohol osobné údaje o ňom spracúvať a mohli byť dotknuté jeho práva.</p>
            <h3>Informačná povinnosť</h3>
            <p>Medzi základné povinnosti prevádzkovateľa podľa zákona o ochrane osobných údajov okrem iného patrí aj základná informačná povinnosť o spracúvaní osobných údajov. Každý, o kom prevádzkovateľa osobné údaje spracúva, je dotknutou osobou. Dotknuté osoby majú právo na poskytnutie základných informácií o spracúvaní ich osobných údajov v rozsahu, ktoré určuje § 19 zákona o ochrane osobných údajov. Dotknutá osoba môže tieto informácie získať:</p>
            <ul>
                <li>na webovej stránke prevádzkovateľa (https://predajkamena.sk/)</li>
                <li>zaslaním písomnej žiadosti na adresu sídla prevádzkovateľa</li>
                <li>zaslaním elektronickej žiadosti na adresu dula@predajkamena.sk</li>
                <li>osobne pri každej návšteve sídla prevádzkovateľa</li>
            </ul>
            <p>Prevádzkovateľ naplní svoju informačnú povinnosť poskytnutím informácií o jednotlivých agendách a informačných systémoch, v ktorých osobné údaje dotknutej osoby spracúva.</p>
            <h3>Práva dotknutých osôb</h3>
            <p>Zákon o ochrane osobných údajov garantuje dotknutým osobám nasledovné práva:</p>
            <ol>
                <li>právo na prístup k osobným údajom</li>
                <li>právo na opravu osobných údajov</li>
                <li>právo na výmaz osobných údajov</li>
                <li>právo na obmedzenie spracúvania osobných údajov</li>
                <li>právo na prenosnosť osobných údajov</li>
                <li>právo namietať spracúvanie osobných údajov</li>
                <li>právo podať návrh na začatie konania o ochrane osobných údajov</li>
            </ol>
            <p>O spôsobe naplnenia práv dotknutých osôb a o ich prípadnom zákonnom obmedzení sa dotknutá osoba dozvie spolu s informáciami o spracúvaní osobných údajov, ktoré jej prevádzkovateľ poskytne ako súčasť naplnenia svojej informačnej povinnosti.</p>
            <p>Každý, kto chce požiadať o naplnenie niektorého z vyššie uvedených práv, môže tak urobiť jedným z nasledovných spôsobov:</p>
            <ul>
                <li>v prípade práv 1. - 6. zaslaním písomnej alebo elektronickej žiadosti, ktorú adresuje prevádzkovateľovi</li>
                <li>v prípade práva podať návrh na začatie konania o ochrane osobných údajov – zaslaním kvalifikovaného návrhu na Úrad na ochranu osobných údajov Hraničná 12, 820 07 Bratislava, www.dataprotektion.gov.sk</li>
            </ul>
            <h3>Zoznam agend a informačných systémov</h3>
            <ul>
                <li><a href="/pdf/01_INFO_PaM_202103.pdf" target="_blank">Agenda - Personalistika a mzdy</a></li>
                <li><a href="/pdf/02_INFO_BOZP_202103.pdf" target="_blank">Agenda - BOZP</a></li>
                <li><a href="/pdf/03_INFO_Uctvovnictvo_202103.pdf" target="_blank">Agenda - Účtovníctvo a evidencia majetku</a></li>
                <li><a href="/pdf/04_INFO_KamerSystem_202103.pdf" target="_blank">Informačný systém - Monitorovanie priestorov</a></li>
                <li><a href="/pdf/05_INFO_Najom_NP_202103.pdf" target="_blank">Agenda - Prenájom nebytových priestorov</a></li>
                <li><a href="/pdf/06_INFO_ObchodneZmluvy_202103.pdf" target="_blank">Agenda - Obchodné zmluvy</a></li>
                <li><a href="/pdf/07_INFO_Kontakty_202103.pdf" target="_blank">Agenda - Obchodné kontakty</a></li>
            </ul>







        </div>
      </div>

    </div>
  </section>
</template>

<script>
export default {
  name: "Ochrana osobných údajov",
  metaInfo() {
    return {
      title: this.metaTagy.title,
      meta : [
        { name:"title", content: this.metaTagy.title },
        { name:"description", content: this.metaTagy.description },
        { name:"keywords", content: this.metaTagy.keywords },
        { property:"og:type", content:"website" },
        { property:"og:url", content:"https://predajkamena.sk" + this.metaTagy.urlPath },
        { property:"og:title", content: this.metaTagy.title },
        { property:"og:description", content: this.metaTagy.description },
        { property:"twitter:card", content:"summary_large_image" },
        { property:"twitter:url", content:"https://predajkamena.sk" + this.metaTagy.urlPath },
        { property:"twitter:title", content: this.metaTagy.title },
        { property:"twitter:description", content: this.metaTagy.description },
      ]
    }
  },
  data() {
    return {
      metaTagy: {
        title: "Ochrana osobných údajov | Predaj prírodného kameňa",
        keywords: "ochrana osovnych udajov, gdpr, andrej dula, kamen, predaj kamena, prirodny kamen, predaj prirodneho kamena, banska bystrica",
        description:"Vyhlásenie o ochrane osobných údajov predajcu prírodného kameňa v Banskej Bystrici.",
        urlPath: this.$router.fullpath
      },
      info: null,
      form: {
        name: "",
        email: "",
        message: "",
        antiSpam: "",
      },
    };
  },
}
</script>